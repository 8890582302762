.modalTitleList{
    display: flex;
    // flex-direction: ;
    gap: 15px; 
    align-items: center;
    justify-content: center; 
}
.modalBodyList {
  display: flex;
  flex-direction: column;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 15px;
    li {
      display: flex;
      gap: 30px;
      align-items: center;
      justify-content: flex-start;

      label{
        font-weight: bold;
      }

      .icon {
        color: #7451f8;
      }
    }
  }
}
