.widget{
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 10px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);
    border-radius: 10px;
    height: 100px;

    .left{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 20px;
        // height: 100%;
        // width: 100%;
        .title{
            font-weight: bold;
            font-size: 14px;
            color: rgb(160,160,160);
            align-self: flex-start;
        }

        .titleText{
            font-weight: bold;
            font-size: 14px;
            color: rgb(160,160,160);
            align-self: flex-start;
        }

        .counter{
            font-size: 28px;
            font-weight: 300;
        }

        .link{
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid gray;
        }

        .percentage{
            display: flex;
            align-items: center;
            font-size: 14px;

            &.positive{
                color: green;
            }
            &.negative{
                color: red;
            }
        }

        .icon{
            width: 100px;
            height: 80px;
            font-size: 12px;
            padding: 5px;
            border-radius: 5px;
            align-items: center;
        }
    }
    .right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        // .title{
        //     font-weight: bold;
        //     font-size: 14px;
        //     color: rgb(160,160,160);
        // }

        .counterText{
            font-size: 80px;
            font-weight: 500;
            align-self: center;
            margin-top: 30px;
        
        }

        // .link{
        //     width: max-content;
        //     font-size: 12px;
        //     border-bottom: 1px solid gray;
        // }

        // .percentage{
        //     display: flex;
        //     align-items: center;
        //     font-size: 14px;

        //     &.positive{
        //         color: green;
        //     }
        //     &.negative{
        //         color: red;
        //     }
        // }

        // .icon{
        //     width: 80px;
        //     height: 80px;
        //     font-size: 12px;
        //     padding: 5px;
        //     border-radius: 5px;
        //     align-self: flex-start;
        // }
    }
}