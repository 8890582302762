.doctorDetail {
  display: flex;
  width: 100%;

  .doctorDetailContainer {
    flex: 6;

    .title,
    .body {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;
      flex-direction: column;
    }

    h1 {
      color: #7451f8;
      font-size: 20px;
    }
    
    h3 {
      color: #696969;
      font-size: 18px;
    }
    
    hr{
      height: 0;
      border: 0.5px solid rgb(230,227,227)
    }
    .photoCard{
      display: flex;
      .left {
        flex: 1;
        text-align: center;
  
        img {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
  
      .middle{
        flex: 1;
        display: flex;
        flex-direction: column;
          ul {
            margin: 0;
            // padding: 0;
            padding: 20px;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 15px;
            li {
              display: flex;
              gap: 15px;
              align-items: center;
              justify-content: flex-start;
    
              label {
                font-weight: bold;
              }
    
              .icon {
                color: #7451f8;
              }
            }
          }

      }
      .right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        text-align: center;
        flex-direction: initial;
        margin: 30px;

        ul {
          margin: 0;
          // padding: 0;
          padding: 0px;
          list-style: none;
          display: flex;
          flex-direction: column;
          gap: 15px;
          li {
            display: flex;
            gap: 15px;
            align-items: center;
            justify-content:right ;
  
            label {
              font-weight: bold;
            }
  
            .icon {
              color: #7451f8;
            }

            .cellWithStatus{
              padding: 2px;
              border-radius: 5px;
      
              &.active{
                  background-color: rgba(0, 128, 0, 0.05);
                  color: green;
              }
              &.pending{
                  background-color: rgba(255, 217, 0, 0.05);
                  color: goldenrod;
              }
              &.deactivate{
                  background-color: rgba(255, 0, 0, 0.05);
                  color: crimson;
              }
              &.admin{
                  background-color: rgba(255, 0, 0, 0.05);
                  color: #7451f8;
              }
          }
          }
        }


        

  
        
  
        // form{
        //     display: flex;
        //     flex-wrap: wrap;
        //     gap: 20px;
  
        //     .formInput{
        //         width: 40%;
  
        //         label{
        //             display: flex;
        //             align-items: center;
        //             gap: 10px;
  
        //             .icon{
        //                 cursor: pointer;
        //             }
        //         }
  
        //         input{
        //             width: 100%;
        //             padding: 5px;
        //             border: none;
        //             border-bottom: 1px solid gray;
  
        //         }
        //     }
  
        //     button{
        //         width: 150px;
        //         padding: 10px;
        //         border: none;
        //         background-color: teal;
        //         color: white;
        //         font-weight: bold;
        //         cursor: pointer;
        //         margin-top: 10px;
        //     }
        // }
      }
    }

    .detailCard{
      display: flex;
      padding: 20px;
      
      

      .left,.right {
        flex: 1;
        display: flex;
        justify-content: start;
        flex-direction: column;
        margin-top: 20px;
      // text-align: center;
        h3{
          display: flex;
          justify-self: start;
        }
      

  
        ul {
          margin: 0;
          padding: 30px 5px 10px 5px;
          list-style: none;
          display: flex;
          flex-direction: column;
          gap: 15px;
          li {
            display: flex;
            gap: 20px;
            // align-items: center;
            justify-content: flex-start;
  
            label {
              font-weight: bold;
            }
  
            // .icon {
            //   color: #7451f8;
            // }

            .money{
              display: flex;
              text-align: center;
              justify-content: start;
              gap: 5px;

              .icon {
                margin-top: 2px;
                // color: #7451f8;
              }
            }
          }
        }
  
        // form{
        //     display: flex;
        //     flex-wrap: wrap;
        //     gap: 20px;
  
        //     .formInput{
        //         width: 40%;
  
        //         label{
        //             display: flex;
        //             align-items: center;
        //             gap: 10px;
  
        //             .icon{
        //                 cursor: pointer;
        //             }
        //         }
  
        //         input{
        //             width: 100%;
        //             padding: 5px;
        //             border: none;
        //             border-bottom: 1px solid gray;
  
        //         }
        //     }
  
        //     button{
        //         width: 150px;
        //         padding: 10px;
        //         border: none;
        //         background-color: teal;
        //         color: white;
        //         font-weight: bold;
        //         cursor: pointer;
        //         margin-top: 10px;
        //     }
        // }
      }
    }

    .historyCard{
      display: flex;
      padding: 20px;
      flex-direction: column;
      
      h3{
        display: flex;
        justify-content: center;
        text-align: center;
      }

      .historyDetailCard{
        display: flex;

        .left,.right {
          flex: 1;
          display: flex;
          justify-content: start;
          flex-direction: column;
          // margin-top: 20px;
        // text-align: center;
          
        
  
    
          ul {
            margin: 0;
            padding: 30px 5px 10px 5px;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 15px;
            li {
              display: flex;
              gap: 20px;
              // align-items: center;
              justify-content: flex-start;
    
              label {
                font-weight: bold;
              }
    
              // .icon {
              //   color: #7451f8;
              // }
  
              .money{
                display: flex;
                text-align: center;
                justify-content: start;
                gap: 5px;
  
                .icon {
                  margin-top: 2px;
                  // color: #7451f8;
                }
              }
            }
          }
    
          // form{
          //     display: flex;
          //     flex-wrap: wrap;
          //     gap: 20px;
    
          //     .formInput{
          //         width: 40%;
    
          //         label{
          //             display: flex;
          //             align-items: center;
          //             gap: 10px;
    
          //             .icon{
          //                 cursor: pointer;
          //             }
          //         }
    
          //         input{
          //             width: 100%;
          //             padding: 5px;
          //             border: none;
          //             border-bottom: 1px solid gray;
    
          //         }
          //     }
    
          //     button{
          //         width: 150px;
          //         padding: 10px;
          //         border: none;
          //         background-color: teal;
          //         color: white;
          //         font-weight: bold;
          //         cursor: pointer;
          //         margin-top: 10px;
          //     }
          // }
        }
      }

    }
  }
}
