.datatable{
    height: 600px;
    padding: 20px;
    // overflow-x: scroll;
    // width: 100%;

    .cellWithImg{
        display: flex;
        align-items: center;


        .icon{
            width: 28px;
            height: 28px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 10px;
            color: #7451f8;
        }
    }

    .cellWithStatus{
        padding: 2px;
        border-radius: 5px;

        &.active{
            background-color: rgba(0, 128, 0, 0.05);
            color: green;
        }
        &.pending{
            background-color: rgba(255, 217, 0, 0.05);
            color: goldenrod;
        }
        &.deactivate{
            background-color: rgba(255, 0, 0, 0.05);
            color: crimson;
        }
        &.admin{
            background-color: rgba(255, 0, 0, 0.05);
            color: #7451f8;
        }
    }

    .cellAction{
        display: flex;
        align-items: center;
        gap: 5px;

        .viewButton{
            padding: 2px 5px;
            border-radius: 5px;
            color: darkblue;
            border: 1px dotted rgba(0,0,130,0.596);
            cursor: pointer;

            &:hover{
                color: #fff;
                border: 1px dotted #fff;
                background-color: rgb(89, 89, 244);
            }
        }
        .deleteButton{
            padding: 2px 5px;
            border-radius: 5px;
            color: crimson;
            border: 1px dotted rgba(220,20,60,0.6);
            cursor: pointer;

            &:hover{
                color: #fff;
                border: 1px dotted #fff;
                background-color: crimson;
            }

            &:disabled{
                pointer-events: none;
                opacity: 0.3;
            }
        }
        .successButton{
            padding: 2px 5px;
            border-radius: 5px;
            color: green;
            border: 1px dotted green;
            cursor: pointer;

            &:hover{
                color: #fff;
                border: 1px dotted #fff;
                background-color: green;
            }

            &:disabled{
                pointer-events: none;
                opacity: 0.3;
            }
        }
    }

    .cellWithIconAndList{
        display: flex;
        flex-direction: column;

        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;

            li{
                display: flex;
                gap: 5px;
                align-items: center;
                justify-content:flex-start;
                margin-bottom: 5px;

                .icon{
                    color: #7451f8;
                }
            }
        }
    }

    .statusList{
        display: flex;
        flex-direction: column;

        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;

            li{
                display: flex;
                gap: 5px;
                align-items: center;
                justify-content:flex-end;

                .icon{
                    color: #7451f8;
                }
            }
        }
    }

    
}