.topbar{
    height: 50px;
    border-bottom: 1px solid rgb(230,227,227);
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555;
    justify-content: center;

    .top{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .logo{
            font-size: 20px;
            font-weight: bold;
            color: #6439ff;
        }
    }

    // .wrapper{
    //     width: 100%;
    //     display: flex;
    //     padding: 20px;
    //     align-items: center;
    //     justify-content: center;
    // }
}