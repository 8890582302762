.list{
    display: flex;
    width: 100%;

    .listContainer{
        flex: 6;

        .tableError{
            display: flex;
            flex: 0;
            margin: 30px;
            font-size: larger;
            font-weight: 600;
            color: crimson;
            background-color: #fcd0d87a;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 40px;
        }
    }
        
}