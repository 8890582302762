.home{
    display: flex;
    .homeContainer{
        flex: 6;

        .tableError{
            display: flex;
            flex: 0;
            margin: 30px;
            font-size: larger;
            font-weight: 600;
            color: crimson;
            background-color: #fcd0d87a;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 40px;
        }

        .widgetsContainer{
            display: flex;
            height: 80vh;
            // flex-direction: column;
            .left,.right{
                display: flex;
                flex-direction: column;
                width: 50%;
                height: fit-content;

                .widgets{
                    display: flex;
                    padding: 20px;
                    gap: 30px;
                    flex-direction: column;
                    height: 100%;

        
                }
            }

        }

        
    }
}