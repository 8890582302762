.downloadReport {
  display: flex;
  width: 100%;

  .downloadReportContainer {
    flex: 6;

    .title,
    .body {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
    }

    .body {
      height: 75vh;
    }

    h1 {
      color: #7451f8;
      font-size: 20px;
    }

    h3 {
      color: #696969;
      font-size: 18px;
    }

    hr {
      height: 0;
      border: 0.5px solid rgb(230, 227, 227)
    }

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1px dotted rgba(0, 0, 130, 0.596);
      cursor: pointer;

      &:hover {
        color: #fff;
        border: 1px dotted #fff;
        background-color: rgb(89, 89, 244);
      }
    }




  }
}