.login{
    display: flex;
    flex-direction: column;
    width: 100%;
    
    .loginContainer{
        display:flex;
        flex: 1;
        background-color: whitesmoke;
        min-height: 100vh;
        justify-content: center;
        align-items: center;

        .loginCard{
            display:flex;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
            box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);
            padding: 10px;
            margin: 20px;
            display: flex;
            flex:-3;
            background-color: #fff;
            width: 500px;
            height: 500px;
            flex-direction: column;
            align-items: center;
            border-radius: 5px;
            justify-content: center;

            img{
                width: 150px;
                height: 150px;
                border-radius: 50%;
                object-fit: cover;
            }

            h1{
                margin: 10px;
                color: #6439ff;;
                font-size: 30px;
                font-weight: bold;
            }

            form{
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 90%;

                .formInput{

                    label{
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .icon{
                            cursor: pointer;
                        }
                    }

                    input{
                        width: 100%;
                        padding: 5px;
                        border: none;
                        border-bottom: 1px solid teal;

                        &:focus{
                            outline: none;
                            box-shadow: 0 0 5px #719ECE;
                            border: 1px solid teal;
                        }

                    }
                }

                .forgetLink{
                    display: flex;
                    align-self: flex-end;
                    color: teal;
                    font-weight: 300;
                    cursor: pointer;

                    &:hover{
                        font-weight: bold;
                        border-bottom: 0.5px dotted teal;
                        
                    }
                }

                button{
                    width: 100%;
                    padding: 10px;
                    border: none;
                    background-color: teal;
                    color: white;
                    font-weight: bold;
                    cursor: pointer;
                    margin-top: 10px;
                    border-radius: 2px;

                    &:hover{
                        background-color: #006666;

                    }
                }
            }
        }

    }
}